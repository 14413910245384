

import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { isEmpty } from '@/common/utils/validation';
import { ROUTE_VALUES } from '@/config/routes/routes';
import { clearToken, getToken } from './storage';

export const setAuthHeader = (token) => {
  if (isEmpty(token)) {
    axios.defaults.headers.common['Authorization'] = null;
  } else {
    if (token.indexOf('Bearer ') !== 0) token = 'Bearer ' + token;
    axios.defaults.headers.common['Authorization'] = token;
  }
}

export const getAuthUserInfo = () => {
  let token = getToken();
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
}

export const handleUnauthorizedAction = () => {
  setAuthHeader(null);
  clearToken();
  window.location.href = ROUTE_VALUES.loginRoute;
}

export const interceptResponse = () => {
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const responseURL = error.request.responseURL;
    if (responseURL.indexOf('/api/auth/login') > -1 || responseURL.indexOf('/api/auth/change-password') > -1) {
      return Promise.reject(error);
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      return handleUnauthorizedAction();
    }

    return Promise.reject(error);
  });
}

//when user load the page
export const setUpAuthorization = () => {
  interceptResponse();

  const user = getAuthUserInfo();
  if (isEmpty(user)) {
    setAuthHeader(null);
    clearToken();
  } else {
    setAuthHeader(getToken())
  }
}
