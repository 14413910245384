import {
  SAVE_ORDER_SEARCH_CONDITION,
  SAVE_PRODUCT_SEARCH_CONDITION,
  SAVE_BLOG_SEARCH_CONDITION,
} from './constants';
import initialState from './state';

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_ORDER_SEARCH_CONDITION:
      return { ...state, orders: action.payload }
    case SAVE_PRODUCT_SEARCH_CONDITION:
      return { ...state, products: action.payload }
    case SAVE_BLOG_SEARCH_CONDITION:
      return { ...state, blogs: action.payload }
    default:
      return state;
  }
}
