import { isEmpty } from "./validation"

export const toNumber = (str) => {
  if (!isNumber(str)) return 0;
  return +str;
}

export const isNumber = (str) => {
  if (isEmpty(str) || isNaN(+str)) return false;
  return true;
}

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
