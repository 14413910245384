import { useState, useEffect } from 'react'
import { toTreeData } from '@/common/utils/array';
import CategoryService from '@/services/category.service';

export default function useTreeCategory() {
  const [treeCategoryData, setTreeCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    try {
      const res = await CategoryService.getCategories();
      setTreeCategoryData(toTreeData(res.data));
    } catch (error) {
      setTreeCategoryData([])
    }
    setLoading(false);
  }

  return [treeCategoryData, loading];
}
