import {
  SET_OPEN_SIDEBAR,
} from './constants'

export const setOpenSidebar = (payload) => {
  return {
    type: SET_OPEN_SIDEBAR,
    payload
  }
}
