import React from 'react';
import { Card } from 'antd';

function CustomCard(props) {
  const { title, children, ...restProps } = props;

  return (
    <Card
      title={<h5>{title}</h5>}
      type="inner"
      {...restProps}
    >
      {children}
    </Card>
  )
}

export default CustomCard;
