export default {
  'message.add_success': 'Added successfully!',
  'message.update_success': 'Updated successfully!',
  'message.delete_success': 'Deleted successfully!',
  'message.add_fail': 'Can not add, please check your input data!',
  'message.update_fail': 'Can not update, please check your input data!',
  'message.delete_fail': 'Can not delete, please check your input data!',
  'message.server_error': "There's something wrong, please contact the admin to check server system!",
  'message.confirm_delete': 'Confirm delete!',
  'message.ok_button_text': 'Confirm',
  'message.cancel_button_text': 'Cancel',
  'message.file_oversize': 'File upload must not over 0.5MB!',
  'message.confirm_logout': 'Are you sure to logout the application?',
  'message.login_success': 'Login successfuly!',
  'message.login_fail': 'Login failed! Please check your username and password!',
  'message.invalid_info': 'Invalid information!',
  'message.category_notfound': 'Can not find this category on the system!',
  'message.store_info_notfound': 'Can not find store info on the system!',
  'message.blog_notfound': 'Can not find this blog on the system!',
  'message.policy_notfound': 'Can not find this policy on the system!',
  'message.website_notfound': 'Can not find website info on the system!',
  'message.product_notfound': 'Can not find this product on the system!',
  'message.order_notfound': 'Can not find this order on the system!',
  'message.main_image_required': 'Main image is required to upload!',
  'message.category_or_brand_required': 'Category or brand must not empty!',
  'message.price_required': 'Price must be a number larger than 0!',
  'message.logo_website_required': 'Logo website is required!',
  'message.sub_page_image_required': 'Sub banner image is required!',
  'message.customer_image_validation': 'Customer partner images must be between 1 - 30!',
  'message.username_required': 'Please input your username!',
  'message.passwrod_required': 'Please input your password!',
  'message.image_required': 'Please upload image!',
  'message.notfound_subtitle': 'This page is not available, please come back to homepage!',
  'message.fail_to_load_due_to_lost_connection': 'The system can not load resources due to lost internet connection, please check your network!',
  'message.lost_connection': 'Lost connection!',
}
