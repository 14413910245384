import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { DashboardLayout } from '@/layouts';
import { ErrorBoundary, Loading, PrivateRoute } from '@/components';

import { routes } from './routes';

function RouteWithSubRoutes(route) {
  return <Route
    path={route.path}
    exact={route.exact}
    render={props => (
      <Suspense fallback={<Loading loading={true} />}>
        <route.component {...props} routes={route.routes} />
      </Suspense>
    )}
  />
}

export default function RouteConfig() {

  const publicRoutes = routes.filter(r => r.public);
  const privateRoutes = routes.filter(r => !r.public);

  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          {publicRoutes.map((route, i) => (
            <RouteWithSubRoutes {...route} key={i} />
          ))}

          <PrivateRoute
            path={'/'}
            component={
              <DashboardLayout>
                <Switch>
                  {privateRoutes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
                </Switch>
              </DashboardLayout>
            }
          />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}
