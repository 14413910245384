import { isEmpty } from "./validation";

export const listToTree = (list) => {
  var map = {}, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    let node = list[i];
    if (!isEmpty(node.parentId) && !isEmpty(list[map[node.parentId]])) {
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  for (i = 0; i < list.length; i += 1) {
    let node = list[i];
    if (node.children.length === 0) node.children = undefined;
  }

  return roots;
}

export const toTreeData = (data) => {
  const treeData = data.map(b => ({
    id: b.id,
    title: b.name,
    value: b.id,
    parentId: b.parentId
  }));
  return listToTree(treeData);
}

