import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { isEmpty } from '@/common/utils/validation';
import { getBase64 } from '@/common/utils/file';
import { getByteSize } from '@/common/utils/string';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.css';


async function uploadCallback(file) {
  const dataUrl = await getBase64(file);
  return { data: { link: dataUrl } };
}

const toolbar = {
  image: {
    uploadCallback: uploadCallback,
    previewImage: true,
    alt: { present: true, mandatory: false },
    inputAccept: 'image/jpeg,image/jpg,image/png',
  }
};

const CustomOption = (props) => {
  const { onChange, editorState } = props;

  const addStar = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '⭐',
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  return (
    <div onClick={addStar}>⭐</div>
  );
}

const StandardEditor = (props, ref) => {
  const { content, onChange } = props;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentSize, setContentSize] = useState(0);

  const getHtmlContent = (editorState) => {
    return draftToHtml(convertToRaw(editorState?.getCurrentContent()));
  }

  const setContent = (content) => {
    let contentBlock;
    if (isEmpty(content)) {
      contentBlock = htmlToDraft('');
    } else {
      contentBlock = htmlToDraft(content);
    }
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(EditorState.moveFocusToEnd(newEditorState));
    setContentSize(getByteSize(content));
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const content = getHtmlContent(editorState);
    setContentSize(getByteSize(content));
    if (onChange) onChange(content);
  };

  useEffect(() => {
    setContent(content);
    // setEditorState(newEditorState);
  }, [content]);

  useImperativeHandle(ref, () => ({
    getHtmlContent: () => getHtmlContent(editorState),
    setContent
  }), [editorState]);

  return (
    <div>
      <div className='text-right'>{contentSize} KB</div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="draft-editor"
        onEditorStateChange={onEditorStateChange}
        onFocus={(event) => { }}
        onBlur={(event, editorState) => { }}
        onTab={(event) => { }}
        toolbar={toolbar}
        toolbarCustomButtons={[<CustomOption />]}
        handlePastedText={() => false}
      />
    </div>
  )
};


export default forwardRef(StandardEditor);
