import React, { useState } from 'react'
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { getBase64, isFileOverSize } from '@/common/utils/file'
import { isEmpty } from '@/common/utils/validation';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const PicturesWall = ({ fileList, onChange, maxQuantity, maxSize, ...rest }) => {

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { t: i18nTranslate } = useTranslation();

  if (isEmpty(fileList)) fileList = [];

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList }) => {
    const length = fileList.length;
    fileList = fileList.filter(file => !isFileOverSize(file.size, maxSize));

    if (length > fileList.length) {
      message.error(i18nTranslate('message.file_oversize'))
    }

    if (fileList.length > maxQuantity) {
      fileList = fileList.filter((file, i) => i < maxQuantity)
    }

    onChange(fileList)
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={dummyRequest}
        {...rest}
      >
        {fileList.length >= maxQuantity ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ maxWidth: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}

export default React.memo(PicturesWall);
