import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

import i18n from '@/config/locales';
import { isEmpty } from '@/common/utils/validation';

function showConfirmModal({ onOk, onCancel, title }) {
  Modal.confirm({
    title: isEmpty(title) ? i18n.t('message.confirm_delete') : title,
    okText: i18n.t('layout.action.ok'),
    cancelText: i18n.t('layout.action.cancel'),
    icon: <ExclamationCircleOutlined />,
    onOk,
  })
}

export default showConfirmModal;
