import React from 'react'
import { Spin } from 'antd';

function Loading({ loading }) {
  return (
    <div className="text-center mt-5">
      {loading && <Spin size="large" />}
    </div>
  )
}

export default Loading;
