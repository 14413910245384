import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const PageBreadcrumb = ({ breadCrumbItems }) => {
  return (
    <Breadcrumb>
      {breadCrumbItems.map((it, idx) => {
        const content = (
          <>
            <span>{it.icon}</span>
            <span className="ml-1">{it.title}</span>
          </>
        );

        return (
          <Breadcrumb.Item key={it.route}>
            {(idx !== breadCrumbItems.length - 1) ? (
              <Link to={it.route}>
                {content}
              </Link>
            ) : (
              <span>
                {content}
              </span>
            )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}

export default PageBreadcrumb;
