/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { Table } from 'antd';
import styles from './index.less';

import PropTypes from 'prop-types';

export const PAGING_DEFAULT = {
  PAGE: 0,
  SIZE: 10,
  PREV: 'prev',
  NEXT: 'next'
};

class StandardTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      loading: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    // clean state
    if (nextProps.selectedRows && nextProps.selectedRows.length === 0) {
      return {
        selectedRowKeys: [],
      };
    }
    return null;
  }

  handleRowSelectChange = (selectedRowKeys, selectedRows = []) => {
    const { onSelectRow } = this.props;
    if (onSelectRow) {
      onSelectRow(selectedRows);
    }
    this.setState({ selectedRowKeys });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { onChange } = this.props;
    this.handleRowSelectChange([], []);
    if (onChange) {
      // this.setState({ loading: true });
      if (!sorter.columnKey || !sorter.order) {
        sorter.columnKey = '';
        sorter.field = '';
      }

      const onChangeHandler = onChange(pagination, filters, sorter);
      // if(onChangeHandler != undefined && onChangeHandler.then != undefined){
      //   onChangeHandler.then(() => this.setState({ loading: false }))
      // }
      // this.setState({ loading: false })
    }
  };

  cleanSelectedKeys = () => {
    this.handleRowSelectChange([], []);
  };

  itemRender = (current, type, originalElement) => {
    if (type === PAGING_DEFAULT.PREV) {
      return <a>Prev</a>;
    }
    if (type === PAGING_DEFAULT.NEXT) {
      return <a>Next</a>;
    }
    return originalElement;
  };

  render() {
    const { selectedRowKeys, loading } = this.state;
    const { data = {}, rowKey, onChange, hideRowSelection, ...rest } = this.props;
    const { list = [], pagination } = data;

    const paginationProps = {
      // itemRender: this.itemRender,
      position: ['topRight', 'bottomRight'],
      ...pagination,
      showSizeChanger: true
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleRowSelectChange,
      // getCheckboxProps: record => ({
      //   disabled: record.disabled,
      //   ...record,
      // }),
    };

    return (
      <Table
        style={{ width: '100%' }}
        rowKey={rowKey || 'key'}
        rowClassName={(record, index) =>
          index % 2 === 0 ? styles.rowLight : styles.rowLightPink
        }
        rowSelection={hideRowSelection ? null : rowSelection}
        dataSource={list}
        pagination={(pagination && pagination.total === 0) ? false : paginationProps}
        onChange={this.handleTableChange}
        {...rest}
      />
    );
  }
}

StandardTable.propTypes = {
  onChange: PropTypes.func,
  hideRowSelection: PropTypes.bool,
};

StandardTable.defaultProps = {
  filter: {},
};

export default StandardTable;
