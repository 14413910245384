import React from 'react'
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';

export default function Notfound(props) {

  const { subTitle, onBack } = props;

  const { t: i18nTranslate } = useTranslation();

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle={subTitle}
        extra={<Button type="primary" onClick={onBack}>{i18nTranslate('layout.action.go_back')}</Button>}
      />
    </div>
  )
}
