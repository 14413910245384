import React, { useState } from 'react'
import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { addRootWeb } from '@/common/utils/file';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function beforeUpload(file) {
  // const isImage = file.type.indexOf('image/') === 0;
  // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  // if (!isImage) {
  //   message.error('Chỉ được upload file ảnh!');
  // }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('Image must smaller than 2MB!');
  // }
  // return isJpgOrPng && isLt2M;
  return true
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};


function UploadImage({ image, onChange }) {
  image = addRootWeb(image);

  const [loading, setLoading] = useState(false);


  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      try {
        const image = await getBase64(info.file.originFileObj)
        onChange(image)
      } catch (error) {
      }
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );


  return (
    <>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        style={{ width: '120px' }}
      >
        {image ? <img src={image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>

    </>
  )
}

export default React.memo(UploadImage)
