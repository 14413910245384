import { ROOT_WEB } from "@/common/constants/app";

import { isEmpty } from "./validation";
import { include } from "./string";

export const addRootWeb = (url) => {
  if (url == null) return "";
  if (include(url, '/images/')) return ROOT_WEB + url
  return url;
}

export const removeRootWeb = (url) => {
  if (include(url, ROOT_WEB)) return url.replace(ROOT_WEB, '');
  return url;
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const isFileOverSize = (fileSize, size) => {
  if (isEmpty(fileSize)) return false;
  return fileSize / 1024 / 1024 > size;
}

export const getDataUrl = async (file, url) => {
  if (isEmpty(file)) return url;
  return await getBase64(file);
}
