import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

import { APP_LANG } from '@/common/constants/enum';
import { IS_DEV_ENV } from '@/common/constants/app';
import viLocales from '@/locales/vi-VN';
import enLocales from '@/locales/en-US';

const APP_LANG_STORAGE_KEY = 'appLang';

const changeAppLanguage = (lang) => {
  localStorage.setItem(APP_LANG_STORAGE_KEY, lang);
  window.location.reload();
}

const getAppLanguage = () => {
  const lang = localStorage.getItem(APP_LANG_STORAGE_KEY);
  return lang || APP_LANG.VI;
}

const resources = {
  en: {
    translation: enLocales
  },
  vi: {
    translation: viLocales
  }
};

i18n
  .use(ICU)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: getAppLanguage(),
    fallbackLng: APP_LANG.VI,
    debug: IS_DEV_ENV,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export {
  changeAppLanguage,
  getAppLanguage
}

export default i18n;
