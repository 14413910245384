import React from 'react';
import { Result } from 'antd';

import i18n from '@/config/locales';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return (
      <>
        {hasError ? (
          <Result
            status="error"
            title={i18n.t('message.lost_connection')}
            subTitle={i18n.t('message.fail_to_load_due_to_lost_connection')}
          />
        ) : children}
      </>
    )
  }
}

export default ErrorBoundary;
