import React from 'react'
import { Form, Input, Card, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const FormItemContent = (props) => {
  const { value, seoHint, onChange, label, isInput } = props;
  const { t: i18nTranslate } = useTranslation();

  return (
    <>
      <small className="d-block">{i18nTranslate(seoHint, { numOfChars: value?.length })}</small>
      {isInput ? (
        <Input
          value={value}
          onChange={onChange}
          placeholder={label}
        />
      ) : (
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 3 }}
          value={value}
          onChange={onChange}
          placeholder={label}
        />
      )}
    </>
  )

}

const MetaDataFormItem = (props) => {

  const { tooltipTitle, name, ...restProps } = props;

  return (
    <Tooltip placement="topLeft" title={tooltipTitle}>
      <div className="my-2">
        <Form.Item name={name}>
          <FormItemContent {...restProps} />
        </Form.Item>
      </div>
    </Tooltip>
  )
}

export default function PageMetaData() {

  const { t: i18nTranslate } = useTranslation();

  return (
    <Card title={i18nTranslate('layout.seo.seo_content')}>
      <MetaDataFormItem
        tooltipTitle={i18nTranslate('layout.seo.title_tooltip')}
        label="Title"
        name={['pageMetadata', 'title']}
        seoHint={'layout.seo.title_hint'}
        isInput={true}
      />
      <MetaDataFormItem
        tooltipTitle={i18nTranslate('layout.seo.keyword_tooltip')}
        label="Keywords"
        name={['pageMetadata', 'keywords']}
        seoHint={'layout.seo.keyword_hint'}
      />
      <MetaDataFormItem
        tooltipTitle={i18nTranslate('layout.seo.description_tooltip')}
        label="Description"
        name={['pageMetadata', 'description']}
        seoHint={'layout.seo.description_hint'}
      />
    </Card>
  )
}
