import {
  SET_OPEN_SIDEBAR ,
} from './constants';
import initialState from './state';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_SIDEBAR:
      return { ...state, openSidebar: action.payload }
    default:
      return state;
  }
}
