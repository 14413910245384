import React from 'react'
import { TreeSelect } from 'antd';
import { include } from '@/common/utils/string';

function StandardTreeSelect({ ...rest }) {

  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeDefaultExpandAll
      filterOption={(inputValue, option) => include(option.title, inputValue)}
      {...rest}
    />
  )
}

export default StandardTreeSelect;
