import React from 'react';
import { BackTop, Divider, PageHeader, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { APP_NAME } from '@/common/constants/app';

import Notfound from '../Notfound';

import './index.css';
function PageContainer(props) {
  const {
    loading,
    notfound,
    title,
    backRoute,
    notfoundSubTitle,
    pageHeaderExtra,
    children,
  } = props;
  const history = useHistory();

  const handleBack = () => {
    history.push(backRoute);
  }

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Helmet>
        <title>{title} - {APP_NAME}</title>
      </Helmet>
      {notfound ? (
        <Notfound
          subTitle={notfoundSubTitle}
          onBack={handleBack}
        />
      ) : (
        <>
          <PageHeader
            className="site-page-header"
            onBack={handleBack}
            title={title}
            extra={pageHeaderExtra}
          />
          <Divider />
          <BackTop style={{ bottom: 150, right: 20 }} />
          {children}
        </>
      )}
    </Spin>
  )
}

PageContainer.propTypes = {
  loading: PropTypes.bool,
  notfound: PropTypes.bool,
  title: PropTypes.string,
  backRoute: PropTypes.string,
  notfoundSubTitle: PropTypes.string,
  breadCrumbItems: PropTypes.array,
};

PageContainer.defaultProps = {
  loading: false,
  notfound: false,
  title: 'Not found',
  backRoute: '/',
  notfoundSubTitle: 'Not Found',
  breadCrumbItems: [],
};

export default PageContainer;

