import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

function Status({ value }) {
  return (
    <div>
      {value ? (
        <CheckCircleOutlined style={{ fontSize: '20px', color: 'green' }} />
      ) : (
        <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} />
      )}
    </div>
  )
}

export default Status;
