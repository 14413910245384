import React from 'react'
import { Menu, Dropdown, Button } from 'antd';

function StandardDropdown({ menu }) {

  const menuComp = (
    <Menu>
      {menu.map((menuItem, i) => (
        <Menu.Item onClick={menuItem.onClick} key={i}>
          { menuItem.text}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menuComp} placement="bottomRight">
      <Button>...</Button>
    </Dropdown>
  )
}

export default React.memo(StandardDropdown)
