import React from 'react'
import { Alert } from 'antd';
import './index.css'

function StandardAlert({ type, message, description, onClose, show }) {
  return show ? (
    <Alert
      message={message}
      type={type}
      description={description}
      showIcon
      onClose={onClose}
      closable />
  ) : null
}

export default StandardAlert;
