import { UBMOTOR_JWT_TOKEN_KEY } from "@/common/constants/auth";

export const getToken = () => {
  return localStorage.getItem(UBMOTOR_JWT_TOKEN_KEY);
};

export const saveToken = (token) => {
  localStorage.setItem(UBMOTOR_JWT_TOKEN_KEY, token);
};

export const clearToken = () => {
  localStorage.removeItem(UBMOTOR_JWT_TOKEN_KEY);
};
