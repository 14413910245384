import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, Spin } from 'antd';

function StandardModal(props, ref) {
  const {
    children,
    loading,
    preventCancel,
    ...rest
  } = props;

  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);

  const handleOpen = () => setVisible(true);

  useImperativeHandle(ref, () => ({
    close: handleClose,
    open: handleOpen,
  }), [visible]);

  return (
    <Modal
      visible={visible}
      onCancel={preventCancel ? null: handleClose}
      {...rest}
    >
      <Spin spinning={!!loading}>
        {children}
      </Spin>
    </Modal>
  )
}

export default forwardRef(StandardModal);
