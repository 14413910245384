import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getAuthUserInfo } from '@/config/auth';
import { isEmpty } from '@/common/utils/validation';
import { ROUTE_VALUES } from '@/config/routes/routes.js';

const PrivateRoute = ({ component, ...rest }) => {

  const user = getAuthUserInfo();

  return (
    <Route
      {...rest}
      render={props => isEmpty(user)
        ? <Redirect to={ROUTE_VALUES.loginRoute} />
        : component
      }
    />
  )
};

export default PrivateRoute;
