import React from 'react';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

function ToggleSidebarButton(props) {

  const { openSidebar, onClick } = props;

  const iconProps = {
    className: 'trigger',
    onClick
  };

  return openSidebar ? (<MenuFoldOutlined {...iconProps} />) : (<MenuUnfoldOutlined {...iconProps} />);
}

export default ToggleSidebarButton;
