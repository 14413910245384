import React from 'react';
import { Provider } from 'react-redux';

import './assets/css/bootstrap.min.css';
import './App.less';

import store from '@/store';
import RouteConfig from '@/config/routes';
import { setUpAuthorization } from '@/config/auth';

function App() {

  setUpAuthorization();

  return (
    <Provider store={store}>
      <RouteConfig />
    </Provider>
  );
}

export default App;
