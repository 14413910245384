import React from 'react'
import { Button, Dropdown, Menu } from 'antd';

import { APP_LANG } from '@/common/constants/enum';
import i18n, { changeAppLanguage, getAppLanguage } from '@/config/locales';
import { GlobalOutlined } from '@ant-design/icons';

export const APP_LANG_LIST = [
  { value: APP_LANG.VI, displayName: i18n.t('layout.header.vietnamese') },
  { value: APP_LANG.EN, displayName: i18n.t('layout.header.english') },
]

function SelectLang() {

  const handleChangeLang = (selectedItem) => {
    changeAppLanguage(selectedItem.key);
  }

  const renderSelectLangMenu = () => {
    const currentAppLang = getAppLanguage();
    return (
      <Menu selectedKeys={[currentAppLang]}>
        {APP_LANG_LIST.map(lang => (
          <Menu.Item key={lang.value} onClick={handleChangeLang}>
            {lang.displayName}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  return (
    <Dropdown overlay={renderSelectLangMenu()} placement="bottomCenter" className="float-right mt-3 mr-3">
      <Button type="link">
        <GlobalOutlined style={{ fontSize: '16px' }} />
      </Button>
    </Dropdown>
  )
}

export default SelectLang
