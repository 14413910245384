import React from 'react';
import { Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';

function ActionButton(props) {
  const {
    type,
    title,
    ...restProps
  } = props;

  return (
    <Tooltip title={title}>
      <Button
        shape="circle"
        type={type}
        {...restProps}
      />
    </Tooltip>
  )
}

ActionButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string
};

ActionButton.defaultProps = {
  type: 'primary',
};

export default ActionButton;
