import React from 'react'
import { InputNumber } from 'antd';
import './index.css'

function InputMoney({ onChange, value, ...rest }) {
  return (
    <InputNumber
      className="input-money"
      value={value}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/\$\s?|(,*)/g, '')}
      onChange={onChange}
      width="100%"
      {...rest}
    />
  )
}

export default InputMoney;
