import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import './styles.css';

function Animation(props) {
  const { children } = props;
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        classNames="fade"
        timeout={3000}
        key={location.key}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  )
}

export default Animation;
