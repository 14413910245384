import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import { Space, Button, } from 'antd';

// import MenuContext from '@/layouts/MenuContext';

import './styles.less';

const DockButton = (props) => {
  const {
    children,
  } = props;

  // const { collapsed, } = useContext(MenuContext) || {};
  // const wrapperClass = classnames(styles.actions, {
  //   [styles.menuCollapsed]: collapsed,
  //   [styles.menuExpand]: !collapsed,
  // });
  // className={wrapperClass}

  return (
    <div className="actions">
      <Space size={4}>
        {children}
        <Button type="" onClick={() => {}}>Quay lại</Button>
      </Space>
    </div>
  )
}

DockButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
}

DockButton.defaultProps = {
  children: null,
}

export default DockButton;
