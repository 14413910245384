import React from "react";
import { Input, Form, Checkbox, Space, Button, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { toNumber } from "@/common/utils/number";

import PicturesWall from "../PicturesWall";

const PicturesUploadForm = (props) => {
  const { name, label, fileFieldname } = props;

  const { t: i18nTranslate } = useTranslation();

  return (
    <>
      <label>{label}</label>
      <Form.List
        name={name}
        rules={[
          {
            validator: async (_, data) => {
              if (!data || data.length === 0) {
                return Promise.reject(
                  new Error(i18nTranslate("message.image_required"))
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <Space
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      padding: "10px",
                    }}
                  >
                    <Form.Item
                      name={[field.name, fileFieldname]}
                      fieldKey={[field.fieldKey, fileFieldname]}
                      valuePropName="fileList"
                      rules={[
                        {
                          type: "array",
                          min: 1,
                          max: 1,
                          required: true,
                          message: i18nTranslate("message.image_required"),
                        },
                      ]}
                      validateTrigger={["onChange", "onBlur"]}
                    >
                      <PicturesWall
                        maxQuantity={1}
                        maxSize={1}
                        multiple={false}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "sortIndex"]}
                      fieldKey={[field.fieldKey, "sortIndex"]}
                      normalize={(val) => toNumber(val)}
                      style={{ width: 100 }}
                    >
                      <Input
                        type="number"
                        placeholder={i18nTranslate("layout.common.sort_index")}
                      />
                    </Form.Item>
                    <Tooltip title={i18nTranslate("layout.common.show_on_web")}>
                      <Form.Item
                        name={[field.name, "showFlag"]}
                        fieldKey={[field.fieldKey, "showFlag"]}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Tooltip>
                    <Form.Item>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              ))}
              <Form.ErrorList errors={errors} />
              <br />
              <Button type="dashed" onClick={() => add()}>
                <PlusOutlined /> {i18nTranslate("layout.action.add_image")}
              </Button>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default PicturesUploadForm;
