import React from 'react'
import { Table } from 'antd';

export default function TableTreeData({ onChange, onSelect, onSelectAll, columns, checkStrictly, dataSource, loading, ...restProps }) {
  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange,
    onSelect,
    onSelectAll,
  };

  return (
    <Table
      columns={columns}
      rowSelection={{ ...rowSelection, checkStrictly }}
      dataSource={dataSource}
      {...restProps}
    />
  )
}
