import axios from 'axios';
import { ROOT_API } from '@/common/constants/app';

const baseUrl = ROOT_API + '/brands'

class BrandService {
  static async getBrands(filter, pagination, order) {
    const url = baseUrl + '/active';
    return axios.get(url);
  }

  static async getBrandDetails(id) {
    const url = baseUrl;
    return axios.get(url);
  }

  static async createBrand(brand) {
    const url = baseUrl;
    return axios.post(url, brand);
  }

  static async updateBrand(brand) {
    const url = baseUrl;
    return axios.put(url, brand);
  }

  static async saveSortIndexes(data) {
    const url = baseUrl + '/sortIndexes';
    return axios.put(url, data);
  }

  static async deleteBrands(ids) {
    const url = baseUrl + '?ids=' + ids.join(',');
    return axios.delete(url);
  }
}

export default BrandService
