import axios from 'axios';
import { ROOT_API } from '@/common/constants/app';

const baseUrl = ROOT_API + '/categories'

class CategoryService {
  static async getCategories(filter, pagination, order) {
    const url = baseUrl + '/active';
    return axios.get(url);
  }

  static async getCategoryDetails(id) {
    const url = baseUrl + '/id/' + id;
    return axios.get(url);
  }

  static async createCategory(category) {
    const url = baseUrl;
    return axios.post(url, category);
  }

  static async updateCategory(category) {
    const url = baseUrl;
    return axios.put(url, category);
  }

  static async deleteCategories(ids) {
    const url = baseUrl + `?ids=` + ids.join(',');
    return axios.delete(url);
  }

  static async saveSortIndexes(data) {
    const url = baseUrl + '/sortIndexes';
    return axios.put(url, data);
  }
}

export default CategoryService
